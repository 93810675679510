<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                {{$t('Información Básica')}}
            </template>
            <template v-slot:subtitle>
                {{$t('solicitada')}}
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-1">
                        <label for="state">{{$t('Nuevo')}}</label>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <Checkbox v-model="nuevo" :binary="true" @change="Nuevo()"/>
                    </div>
                    <div class="p-col-12 p-md-12" v-if="!mostrar">
						<DataTable :value="usuarios" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
							<template #header>
								<div class="table-header">
									<h5>{{$t('Clientes')}}</h5>
									<span class="p-input-icon-right">
										<i class="pi pi-search"/>
										<InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
									</span>
								</div>
							</template>
							<Column field="cliente" :header="$t('ID')+' #'" :sortable="true" headerStyle="width: 70px">
								<template #body="slotProps">
									{{slotProps.data.cliente}}                         
								</template>
							</Column>
							<Column field="nombre"  :header="$t('Nombre Completo')">
								<template #body="slotProps">
									<div>{{slotProps.data.nombre}}</div>     
								</template>
							</Column>       
							<Column field="correo"  :header="$t('Correo')">
								<template #body="slotProps">
									<div>{{slotProps.data.correo}}</div>     
								</template>
							</Column>       
							<Column field="vence" :header="$t('Acciones')" :sortable="true" headerStyle="width: 70px">
								<template #body="slotProps">
                                    <Button icon="pi pi-check-circle" class="p-button-rounded p-button-secondary" @click="Seleccion(slotProps.data)"/>
								</template>
							</Column>
						</DataTable>
                    </div>
                </div>

                <div class="p-fluid p-formgrid p-grid" v-if="mostrar">
					<div class="p-field p-col-12 p-md-4">
						<label for="state">{{$t('Dirigido a')}}</label>
						<Dropdown v-model="datos.tipocliente" :options="tipoclientes" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                        <small v-show="validationErrors.tipocliente && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-4">
						<label for="state">{{$t('Tipo Póliza')}}</label>
						<Dropdown v-model="datos.tipopoliza" :options="tipopolizas" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                        <small v-show="validationErrors.tipocliente && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-4" v-if="datos.tipopoliza">
                        <div v-if="datos.tipopoliza && i18n.locale() == 'es'">{{datos.tipopoliza.descrip1}}</div>
                        <div v-if="datos.tipopoliza && i18n.locale() == 'en'">{{datos.tipopoliza.descrip2}}</div>
					</div>
                </div>
                <div class="p-fluid p-formgrid p-grid" v-if="mostrar">
					<div class="p-field p-col-12 p-md-2">
						<label>{{$t('Número ID')}}</label>
						<InputText v-model="datos.code" type="text"/>
                        <small v-show="validationErrors.code && submitted" class="p-error">{{$t('required')}}</small>
					</div>
                    <div class="p-field p-col-12 p-md-6">
                        <label>{{$t('Nombre Completo')}} / {{$t('Compañía')}}</label>
                        <InputText v-model="datos.nombre" type="text" :disabled="nuevo==false"/>
                        <small v-show="validationErrors.nombre && submitted" class="p-error">{{$t('required')}}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <label>{{$t('Correo')}}</label>
                        <InputText v-model="datos.correo" type="text" :disabled="nuevo==false"/>
                         <small v-show="validationErrors.correo && submitted" class="p-error">{{$t('required')}}</small>
                    </div>
					<div class="p-field p-col-12 p-md-4">
						<label>{{$t('Nacionalidad')}}</label>
						<Dropdown v-model="datos.nacionalidad" :options="nacionalidades" optionLabel="nacionalidad" :filter="true" placeholder="Selecciona..." v-if="i18n.locale() == 'es'">
							<template #value="slotProps">
								<div class="p-dropdown-car-value" v-if="slotProps.value">
									<span> {{slotProps.value.nacionalidad}}</span>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
							<template #option="slotProps">
								<div class="p-dropdown-car-option">
									<span> {{slotProps.option.nacionalidad}}</span>
								</div>
							</template>
						</Dropdown>
						<Dropdown v-model="datos.nacionalidad" :options="nacionalidades" optionLabel="nationality" :filter="true" placeholder="Selecciona..." v-if="i18n.locale() == 'en'">
							<template #value="slotProps">
								<div class="p-dropdown-car-value" v-if="slotProps.value">
									<span> {{slotProps.value.nationality}}</span>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
							<template #option="slotProps">
								<div class="p-dropdown-car-option">
									<span> {{slotProps.option.nationality}}</span>
								</div>
							</template>
						</Dropdown>
                        <small v-show="validationErrors.nacionalidad && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-2">
						<label>{{$t('Teléfono móvil')}}</label>
						<InputMask v-model="datos.celular" mask="+99999 999999"/>
                        <small class="p-error" v-if="i18n.locale() == 'es'">Incluir código del país</small>
                        <small class="p-error" v-if="i18n.locale() == 'en'">Include country code</small>                    
                        <small v-show="validationErrors.celular && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-2">
						<label>{{$t('Teléfono privado')}}</label>
						<InputMask v-model="datos.tlflocal" mask="+99999 999999"/>
                        <small class="p-error" v-if="i18n.locale() == 'es'">Incluir código del país</small>
                        <small class="p-error" v-if="i18n.locale() == 'en'">Include country code</small>                    
					</div>                      
					<div class="p-field p-col-12 p-md-4">
						<label>{{$t('Localidad')}}</label>
                        <InputText v-model="datos.localidad.nombre" type="text" disabled v-if="i18n.locale() == 'es'"/>
                        <InputText v-model="datos.localidad.name" type="text" disabled v-if="i18n.locale() == 'en'"/>
					</div>
                    <div class="p-field p-col-2"> 
                        <label>{{$t('Documento Identidad')}}</label>
                        <FileUpload :chooseLabel="$t('Subir Foto')+ ' JPG,JPEG'" uploadLabel="Cargar" mode="basic" :name="img" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader(img)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
                        <small v-show="validationErrors.documento" class="p-error">{{$t('required')}}</small>
                    </div>
                    <div class="p-field p-col-2"> 
                        <img style="width: 60px;" :src="url.upload+datos.documento" v-if="datos.documento"/>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :label="$t('Siguiente')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";
import url from "@/service/_URL";

export default {
    data () {
        return {
			size: '60vw',
			i18n: null,
			perfil: null,
			tipoclientes: null,
            usuarios: [],
			tipopolizas: [],
			nacionalidades: null,
			localidades: null,
			sexos: null,
			datos: {
                id: 0,
                tipopoliza: null,
                tercero: false,
                code: null,
                nombre: null,
                correo: null,
                celular: null,
                tlflocal: null,
                nacionalidad: null,
                localidad: null,
                tipocliente: null,
                sexo: null,
                fecha: null,
                documento: null
			},
            img: null,
            submitted: false,
            validationErrors: {},
            mostrar: false,
            nuevo: false,
            selectedCustomers: null,
			filters: {},
        }
    },
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
        this.$store.commit('Loading');
        this.usuarios = [];
        const Consulta = new API('Cotizacions');
		Consulta.Ini('Incendio').then(result => {
			//this.$store.state.error = result.consult;
            this.usuarios = result.consult.usuarios;
			this.perfil = result.consult.perfil;
			this.tipopolizas = result.consult.tipopolizas;
			this.tipoclientes = result.consult.tipoclientes;
            this.nacionalidades = result.consult.nacionalidades;
            this.localidades = [result.consult.localidades];
            this.datos.localidad = result.consult.localidades;
			this.sexos = result.consult.sexos;
            this.$store.commit('Loading');
		}); 
		const tokgen = new Token(256);
		this.img = tokgen.generate();
	},
    methods: {
        Nuevo() {
            if(this.nuevo){
                this.mostrar = true;
                this.nuevo = true;
            } else {
                this.nuevo = false;
                this.mostrar = false;
            }
                this.datos.id = 0,
                this.datos.tipopoliza = null,
                this.datos.tercero = false,
                this.datos.code = null,
                this.datos.nombre = null,
                this.datos.correo = null,
                this.datos.celular = null,
                this.datos.tlflocal = null,
                this.datos.nacionalidad = null,
                this.datos.tipocliente = null,
                this.datos.sexo = null,
                this.datos.fecha = null,
                this.datos.documento = null               
        },
        Seleccion(data) {
            this.mostrar = true;
            this.nuevo = false;
            this.datos.id = data.id;
            this.datos.code = data.code;
            this.datos.nombre = data.nombre;
            this.datos.correo = data.correo;
            this.datos.celular = data.celular;
			this.datos.tlflocal = data.tlflocal;
			this.datos.fecha = data.fechnac;
            this.nacionalidades.forEach(element => {
                if(data.nacionalidad==element.code){
                    this.datos.nacionalidad = element;
                }
            });  
            this.localidades.forEach(element => {
                if(data.localidad==element.code){
                    this.datos.localidad = element;
                }
            });  
            this.sexos.forEach(element => {
                if(data.sexo==element.code){
                    this.datos.sexo = element;
                }
            });  
        },
		myUploader(img) {
			this.$store.commit('Loading');
			const Consulta = new API('Cotizacions');
			Consulta.Procesar('Proyecto',{
				caso: 	'RevFoto',
				foto: img,
			}).then(response => {
                //this.$store.state.error = response;	
				if(response.result){
					this.datos.documento = response.result;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
				this.$store.commit('Loading');
			});
		},
        prevPage() {
            this.$router.push({ path: '/quotations'});
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('next-page', {formData: {datos: this.datos, nuevo: this.nuevo}, pageIndex: 0});
            }
        },
        validateForm() {
            if (!this.datos.tipocliente)
                this.validationErrors['tipocliente'] = true;
            else
                delete this.validationErrors['tipocliente'];
            if (!this.datos.code)
                this.validationErrors['code'] = true;
            else
                delete this.validationErrors['code'];
            if (!this.datos.nombre)
                this.validationErrors['nombre'] = true;
            else
                delete this.validationErrors['nombre'];
            if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
                test(this.datos.correo))
                this.validationErrors['correo'] = true;
            else
                delete this.validationErrors['correo'];
            if (!this.datos.celular)
                this.validationErrors['celular'] = true;
            else
                delete this.validationErrors['celular'];
            if (!this.datos.nacionalidad)
                this.validationErrors['nacionalidad'] = true;
            else
                delete this.validationErrors['nacionalidad'];
            if (!this.datos.localidad)
                this.validationErrors['localidad'] = true;
            else
                delete this.validationErrors['localidad'];
                /*
            if (!this.datos.fecha && this.datos.tipocliente.code==1)
                this.validationErrors['fecha'] = true;
            else
                delete this.validationErrors['fecha'];
            if (!this.datos.sexo && this.datos.tipocliente.code==1)
                this.validationErrors['sexo'] = true;
            else
                delete this.validationErrors['sexo'];
                */
            if (!this.datos.documento)
                this.validationErrors['documento'] = true;
            else
                delete this.validationErrors['documento'];

            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>